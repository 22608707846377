import { useTranslation } from "next-i18next";

export const Links1 = () => {
   const { t } = useTranslation("common");
   return [
      {
         label: t("footer-resources"),
         href: "https://blog.metricks.io",
      },
      // {
      //    label: "FAQ",
      //    href: "/faq",
      // },
      // {
      //    label: "Learning Center",
      //    href: "/learning-center",
      // },
      // {
      //    label: "Developer Docs",
      //    href: "/developer-documentation",
      // },
      // {
      //    label: "Glossary",
      //    href: "/glossary",
      // },
   ];
};

export const Links2 = () => {
   const { t } = useTranslation("common");
   return [
      // {
      //    label: "Pricing",
      //    href: "/pricing",
      // },
      {
         label: t("footer-features"),
         href: "/features",
      },
      {
         label: t("footer-integrations"),
         href: "/integrations",
      },
      // {
      //    label: t('footer-signup'),
      //    href: "/signup",
      // },
      {
         label: t("footer-login"),
         href: "https://app.metricks.io/login",
      },
   ];
};

export const Links3 = () => {
   const { t } = useTranslation("common");
   return [
      {
         label: t("footer-about"),
         href: "/about",
      },
      {
         label: t("footer-contact"),
         href: "/contact",
      },
      {
         label: t("footer-affiliate-program"),
         href: "/affiliate-program",
      },
   ];
};

export const Links4 = () => {
   const { t } = useTranslation("common");
   return [
      {
         label: t("footer-terms"),
         href: "/terms",
      },
      {
         label: t("footer-privacy"),
         href: "privacy-policy",
      },
   ];
};
