import { useRouter } from "next/router";

const useHelper = () => {
   const router = useRouter();
   function handleCta() {
      return router.push("https://app.metricks.io/login");
   }
   function handleRouteToLogin() {
      return router.push("https://app.metricks.io/login");
   }
   return { handleCta, handleRouteToLogin };
};

export default useHelper;
