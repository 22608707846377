import styled from "styled-components";
import { responsive } from "theme/responsive";

export const FooterWrapper = styled.footer`
   position: relative;
   display: grid;
   grid-template-columns: 34% 60%;
   gap: 2em;
   padding: 3em;
   background: linear-gradient(180deg, #210045 0%, #0c0123 100%);

   ${responsive("$small")`
   grid-template-columns: repeat(1, 1fr);
   `}
`;

export const SocialWrapper = styled.div`
   display: flex;
   flex-direction: column;
   align-items: center;
   gap: 1.5em;
`;

export const FooterLinks = styled.div`
   display: flex;
   justify-content: space-between;
   gap: 1em;
   z-index: 10;
   ${responsive("$small")`
   display: grid;
   grid-template-columns: repeat(2, 1fr);
   gap: 2.5em;
   `}
`;

export const FooterItem = styled.ul`
   list-style: none;
   display: flex;
   flex-direction: column;
   li {
      margin: 0.5em 0;
   }

   a {
      text-decoration: none;
      font-size: 14px;
      color: ${({ theme }) => theme.colors?.white};
   }

   & .title {
      font-size: 20px;
      color: ${({ theme }) => theme.colors?.white};
      font-weight: ${({ theme }) => theme.fontWeight?.bold};
   }
`;

export const FooterCirclePos1 = styled.div`
   position: absolute;
   left: 61.57px;
   bottom: 40px;
`;

export const FooterCirclePos2 = styled.div`
   position: fixed;
   left: 0;
   bottom: -150px;
`;

export const FooterCirclePos3 = styled.div`
   position: absolute;
   right: 0;
   bottom: 100px;
`;

export const FooterCirclePos4 = styled.div`
   position: absolute;
   right: 70px;
   bottom: 10px;
`;
