import styled from "styled-components";
import { arrowBounce } from "styles/animations";
import { responsive } from "theme/responsive";

export const Block = styled.div`
   ${responsive("$large")`
   margin-top: 15vh;
`}
`;

export const NavbarContainer = styled.div`
   height: 5rem;
   display: flex;
   justify-content: space-between;
   align-items: center;
   padding: 0 92px;
   width: 100%;
   transition: height 300ms ease-in-out;
   z-index: 500;
   top: 0;
   position: fixed;
   left: 0;
   right:0;
   background-color: #fcfbff;

   ${responsive("$medium")`
   padding: 0 1.5em;
   `}

   .bounce-right-anim {
      animation-name: ${arrowBounce};
      animation-duration: 5s;
      animation-timinig-function: cubic-bezier(0.55, 0, 1, 0.45);
      animation-iteration-count: infinite;
   }

   ${responsive("$large")`
   button {
     display: none
      
   }

  .more-icon {
      .burger { display: block }
  }

  .large-nav-links {
     display: none
  }
   `}

   .mobile-nav-links {
      position: fixed;
      top: 0;
      bottom: 0;
      right: 0;
      width: 0;
      overflow: hidden;
      background: ${({ theme }) => theme.colors?.white};
      padding: 70px 0px 20px 0px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      z-index: 1500;
      transition: all 300ms ease-in-out;
      color: white;
      list-style-type: none;
      box-sizing: border-box;

      li {
         margin-bottom: 2.5em;
      }

      a {
         text-decoration: none;
      }
   }

   .toggled .line1 {
      transform: rotate(-45deg) translate(-5px, 6px);
   }
   .toggled .line2 {
      opacity: 0;
   }
   .toggled .line3 {
      transform: rotate(45deg) translate(-3px, -6px);
   }

   .link {
      color: white;
      text-decoration: none;
   }

   &.active {
      .mobile-nav-links {
         width: 100%;
      }
   }

   .more-icon {
      z-index: 20000;
      position: fixed;
      cursor: pointer;
      right: 3em;
      height: 57px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0px 10px;
      .burger {
         background: transparent;
         border: none;
         outline: none;
         div {
            width: 20px;
            height: 2px;
            margin-bottom: 5px;
            background: #000;
            transition: all 0.3s ease;
         }

         @media screen and (min-width: 768px) {
            display: none;
         }
      }
   }
`;

export const NavLinks = styled.div`
   font-size: 1.5rem;
   display: flex;
   align-items: center;
   padding: 0 15px;

   ${responsive("$large")`
      flex-direction: column;
      align-items: flex-start;

      a {
         display: block;
         font-size: 14px;
         height: 45px;
      }
   `}
`;

export const NavLink = styled.a`
   text-decoration: none;
   font-size: 12px;
   font-weight: ${({ theme }) => theme.fontWeight?.semiBold};
   color: #000;

   &:not(:first-child) {
      margin-left: 35px;

      ${responsive("$large")`
         margin-left: 0px;
      `}
   }

   &.active {
      color: ${({ theme }) => theme.colors?.primary};
      font-weight: ${({ theme }) => theme.fontWeight?.medium};
   }
`;
