import styled from "styled-components";
import { responsive } from "theme/responsive";

export const BtnFlex = styled.div`
   display: flex;
   align-items: center;
   gap: 1.5em;

   ${responsive("$small")`
   display: flex;
   flex-direction: column;
   gap: 0;

   button {
      margin-bottom: 1.5em;
   }
   `}
`;

export const UnsubscribeWrapper = styled.div`
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   gap: 2em;
`;

export const FormBox = styled.div`
   display: flex;
   flex-direction: column;
   gap: 2em;
`;

export const List = styled.ul`
   list-style: none;

   li {
      margin-bottom: 2em;
      p {
         margin-left: 3em;
      }
   }
`;
