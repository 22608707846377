import { keyframes } from "styled-components";

export const arrowBounce = keyframes`
   0% {
      animation-timing-function: ease-in;
      opacity: 1;
      transform: translateX(48px);
   }

   24% {
      opacity: 1;
   }

   40% {
      animation-timing-function: ease-in;
      transform: translateX(26px);
   }

   65% {
      animation-timing-function: ease-in;
      transform: translateX(13px);
   }

   82% {
      animation-timing-function: ease-in;
      transform: translateX(6.5px);
   }

   93% {
      animation-timing-function: ease-in;
      transform: translateX(4px);
   }

   25%,
   55%,
   75%,
   87%,
   98% {
      animation-timing-function: ease-out;
      transform: translateX(0px);
   }

   100% {
      animation-timing-function: ease-out;
      opacity: 1;
      transform: translateX(0px);
   }

`;

export const slideInAnimation = keyframes`
   0% {
         opacity: 0;
         transform: translateX(250px);
      }

      100% {
         opacity: 1;
         transform: translateX(0);
      }

`;

export const slideInLeftAnimation = keyframes`
   0% {
         opacity: 0;
         transform: translateX(-250px);
      }

      100% {
         opacity: 1;
         transform: translateX(0);
      }

`;

export const slideUpLeftAnimation = keyframes`
   0% {
         opacity: 0;
         transform: translateY(250px);
      }

      100% {
         opacity: 1;
         transform: translateY(0) rotate(-16.739 29.8744 30.2972);
      }

`;
export const slideUpRightAnimation = keyframes`
   0% {
         opacity: 0;
         transform: translateY(250px);
      }

      100% {
         opacity: 1;
         transform: translateY(0) rotate(47.4513 1147.16 48.5595);
      }

`;

export const slideDownLeftAnimation = keyframes`
   0% {
         opacity: 0;
         transform: translateY(-250px);
      }
      100%{
         opacity: 1;
         transform: translateY(0) rotate(-16.739 29.8744 30.2972);
      }

   
`;

export const slideDownRightAnimation = keyframes`
   0% {
         opacity: 0;
         transform: translateY(-250px);
      }
      100%{
         opacity: 1;
         transform: translateY(0) rotate(47.4513 1147.16 48.5595);
      }

   
`;

export const openTextAnimation = keyframes`

  0% {
    transform: scaleX(0.4);
    transform-origin: 0% 0%;
  }

  100% {
    transform: scaleX(1);
    transform-origin: 0% 0%;
  }
`;

export const iconShakeAnimation = keyframes`
  0%,
  100% {
    transform: translateX(0%);
    transform-origin: 50% 50%;
  }

  15% {
    transform: translateX(-30px) rotate(6deg);
  }

  30% {
    transform: translateX(15px) rotate(-6deg);
  }

  45% {
    transform: translateX(-15px) rotate(3.6deg);
  }

  60% {
    transform: translateX(9px) rotate(-2.4deg);
  }

  75% {
    transform: translateX(-6px) rotate(1.2deg);
  }

`;

export const scaleUpAnimation = keyframes`

  0% {
     opacity: 0;
    transform: scale(0.5);
    transform-origin: 100% 50%;
  }
  
  100% {
     opacity: 1;
    transform: scale(1);
    transform-origin: 100% 50%;
  }

`;
export const scaleUpCardAnimation = keyframes`
 0% {
    transform: scale(0.5);
    transform-origin: 50% 100%;
  }

  100% {
    transform: scale(1);
    transform-origin: 50% 100%;
  }
`;
