/* eslint-disable react/jsx-no-target-blank */
import React from "react";
import Image from "next/image";
import Link from "next/link";
import { useTranslation } from "next-i18next";
import Facebook from "assets/icons/facebook.png";
import Telegram from "assets/icons/telegram.png";
import { FB, YT, TWI, INSTA, LNK } from "assets/vectors";
import { BrandImageWhite } from "components/brand-image";
import { Button } from "components/button";
import { BtnFlex } from "containers/unsubscribe/unsubscribe.styles";
import { FooterLinks, FooterWrapper, SocialWrapper, FooterItem } from "./footer.styles";
import { Links1, Links2, Links3, Links4 } from "./footer.utils";

const Footer = () => {
   const { t } = useTranslation("common");
   const links1 = Links1();
   const links2 = Links2();
   const links3 = Links3();
   const links4 = Links4();
   return (
      <FooterWrapper>
         <SocialWrapper>
            <BrandImageWhite />
            <div
               style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "1em",
               }}
            >
               <a href="https://www.youtube.com/channel/UC8FRSb8AmMk0YFDt_M7Ypmw" target="_blank">
                  <YT />
               </a>
               <a href="https://m.facebook.com/metricks.io" target="_blank">
                  <FB />
               </a>
               <a href="https://www.linkedin.com/company/metricks-io/" target="_blank">
                  <LNK />
               </a>
               <a href="https://www.instagram.com/metricks.io/" target="_blank">
                  <INSTA />
               </a>
               <a href="https://twitter.com/metricks_io?s=21" target="_blank">
                  <TWI />
               </a>
            </div>
            <BtnFlex>
               <a
                  style={{
                     textDecoration: "none",
                  }}
                  target="_blank"
                  href="https://t.me/+u23cD1VvabNkZjE0"
               >
                  <Button variant="outline" width="100%" style={{ height: "30px", padding: 10 }}>
                     <div
                        style={{
                           width: "100%",
                           display: "flex",
                           justifyContent: "space-between",
                           alignItems: "center",
                           gap: ".5em",
                        }}
                     >
                        <p style={{ fontSize: 10, lineHeight: 1.3 }}>
                           {t("footer-telegram")}
                           <br />
                           {t("footer-community")}
                        </p>
                        <Image src={Telegram} alt={t("footer-telegram")} />
                     </div>
                  </Button>
               </a>

               <a
                  style={{
                     textDecoration: "none",
                  }}
                  target="_blank"
                  href="https://www.facebook.com/groups/metricks/?ref=share"
               >
                  <Button variant="outline" width="100%" style={{ height: "30px", padding: 10 }}>
                     <div
                        style={{
                           width: "100%",
                           display: "flex",
                           justifyContent: "space-between",
                           alignItems: "center",
                           gap: ".5em",
                        }}
                     >
                        <p style={{ maxWidth: "100%", fontSize: 10, lineHeight: 1.3 }}>
                           {t("footer-facebook")}
                           <br />
                           {t("footer-community")}
                        </p>
                        <Image src={Facebook} alt="Join our telegram community" />
                     </div>
                  </Button>
               </a>
            </BtnFlex>
         </SocialWrapper>
         <FooterLinks>
            <FooterItem>
               <li className="title">{t("footer-resources")}</li>
               {links1.map((link, idx) => (
                  <li key={idx}>
                     <Link href={link.href}>
                        <a>{link.label}</a>
                     </Link>
                  </li>
               ))}
            </FooterItem>
            <FooterItem>
               <li className="title">{t("footer-product")}</li>
               {links2.map((link, idx) => (
                  <li key={idx}>
                     <Link href={link.href}>
                        <a>{link.label}</a>
                     </Link>
                  </li>
               ))}
            </FooterItem>
            <FooterItem>
               <li className="title">{t("footer-company")}</li>
               {links3.map((link, idx) => (
                  <li key={idx}>
                     <Link href={link.href}>
                        <a>{link.label}</a>
                     </Link>
                  </li>
               ))}
            </FooterItem>
            <FooterItem>
               <li className="title">{t("footer-legal")}</li>
               {links4.map((link, idx) => (
                  <li key={idx}>
                     <Link href={link.href}>
                        <a>{link.label}</a>
                     </Link>
                  </li>
               ))}
            </FooterItem>
         </FooterLinks>
      </FooterWrapper>
   );
};

export { Footer };
