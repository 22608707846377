import React, { useState } from "react";
import Link from "next/link";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import { ArrowLeft } from "assets/vectors";
import { BrandImage } from "components/brand-image";
import { Button } from "components/button";
// import LocaleSwitcher from "components/language-selector/index";
import useHelper from "helpers";
import { NavbarContainer, NavLinks, NavLink, Block } from "./navbar.styles";

const NavBar = () => {
   const router = useRouter();
   const [isNavActive, setIsNavActive] = useState(false);

   const { handleCta, handleRouteToLogin } = useHelper();
   const { t } = useTranslation("common");

   return (
      <>
         <Block></Block>
         <NavbarContainer className={` ${isNavActive ? "active" : ""}`}>
            <BrandImage />
            <NavLinks className="large-nav-links">
               <Link href="/features" passHref>
                  <NavLink className={`${router.pathname === "/features" ? "active" : ""}`}>
                     {t("navbar-features")}
                  </NavLink>
               </Link>
               <Link href="/pricing" passHref>
                  <NavLink className={`${router.pathname === "/pricing" ? "active" : ""}`}>
                     Pricing
                  </NavLink>
               </Link>
               {/* 
               <Link href="https://blog.metricks.io" passHref>
                  <NavLink
                     className={`${router.pathname === "https://blog.metricks.io" ? "active" : ""}`}
                  >
                     Blog
                  </NavLink>
               </Link> */}

               <Link href="https://blog.metricks.io" passHref>
                  <a
                     style={{ marginLeft: 25, textDecoration: "none", marginTop: "-8px" }}
                     target="_blank"
                     rel="noreferrer"
                     href="https://blog.metricks.io"
                  >
                     <NavLink
                        className={`${
                           router.pathname === "https://blog.metricks.io" ? "active" : ""
                        }`}
                     >
                        {t("navbar-resources")}
                     </NavLink>
                  </a>
               </Link>

               {/* <Link href="/blog" passHref>
                  <NavLink className={`${router.pathname === "/blog" ? "active" : ""}`}>
                     Blog
                  </NavLink>
               </Link> */}

               <Link href="/integrations" passHref>
                  <NavLink className={`${router.pathname === "/intregrations" ? "active" : ""}`}>
                     {t("navbar-integrations")}
                  </NavLink>
               </Link>

               <Link href="/about" passHref>
                  <NavLink className={`${router.pathname === "/about" ? "active" : ""}`}>
                     {t("navbar-about")}
                  </NavLink>
               </Link>
            </NavLinks>

            <div style={{ display: "flex", gap: "1em" }}>
               <Button
                  variant="outline"
                  style={{ border: "solid 1px #7211d4", color: "#7211d4" }}
                  size="regular"
                  width="120"
                  onClick={() => handleRouteToLogin()}
               >
                  {t("navbar-login")}
               </Button>

               {/* <Button variant="primary" size="regular" width="180" onClick={handleCta}> */}
               {/* {t("navbar-signup")} <ArrowLeft /> */}
               {/* Start Now <ArrowLeft />
               </Button> */}
               {/* <LocaleSwitcher /> */}
            </div>

            <ul className="mobile-nav-links">
               <li>
                  <Link href="/features" passHref>
                     <NavLink className={`${router.pathname === "/features" ? "active" : ""}`}>
                        {t("navbar-features")}
                     </NavLink>
                  </Link>
               </li>
               <li>
                  <Link href="/pricing" passHref>
                     <NavLink className={`${router.pathname === "/pricing" ? "active" : ""}`}>
                        Pricing
                     </NavLink>
                  </Link>
               </li>
               {/* <li>
                  <Link href="/blog" passHref>
                     <NavLink className={`${router.pathname === "/blog" ? "active" : ""}`}>
                        Blog
                     </NavLink>
                  </Link>
               </li> */}
               <li>
                  <Link href="https://blog.metricks.io" passHref>
                     <a
                        style={{ marginLeft: 0, textDecoration: "none", marginTop: "-8px" }}
                        target="_blank"
                        rel="noreferrer"
                        href="https://blog.metricks.io"
                     >
                        <NavLink
                           className={`${
                              router.pathname === "https://blog.metricks.io" ? "active" : ""
                           }`}
                        >
                           {t("navbar-resources")}
                        </NavLink>
                     </a>
                  </Link>
               </li>
               <li>
                  <Link href="/integrations" passHref>
                     <NavLink className={`${router.pathname === "/intregrations" ? "active" : ""}`}>
                        {t("navbar-integrations")}
                     </NavLink>
                  </Link>
               </li>

               <li>
                  <Link href="/about" passHref>
                     <NavLink className={`${router.pathname === "/about" ? "active" : ""}`}>
                        {t("navbar-about")}
                     </NavLink>
                  </Link>
               </li>
            </ul>

            <div className="more-icon">
               <button
                  className={`burger ${isNavActive ? "toggled" : ""}`}
                  onClick={() => setIsNavActive(!isNavActive)}
               >
                  <div className="line line1" />
                  <div className="line line2" />
                  <div className="line line3" />
               </button>
            </div>
         </NavbarContainer>
      </>
   );
};

export { NavBar };
